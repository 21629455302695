import React, { Component } from 'react';

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = { email: '', name: '', help: ''};
  }

  sendMessage = async e => {

    this.setState({inProgress: true, check: false});

    var req = {
      email: this.state.email,
      name: this.state.name,
      help: this.state.help,
      a_password: this.state.a_password
    };

    var url = '/api/contact';
    let response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      },
      body: JSON.stringify(req)
    });

    let responseJson = await response.json();
    
    this.setState({inProgress: false, status: responseJson.status == "OK"});
  }  

  changeEmail = e => {
    this.setState({email: e.target.value });
  }
  changeName = e => {
    this.setState({name: e.target.value });
  }
  changeHelp = e => {
    this.setState({help: e.target.value });
  }

  changePwd = e => {
    this.setState({a_password: e.target.value });
  }

  renderCapture() {
    return (
    <div>
      <h1 class="title">Contact us</h1>
      <h2 class="subtitle">Please fill out the form below</h2>     
      <div className="field">
        <label className="label" htmlFor="email">Your email</label>
        <div className="control has-icons-left">
          <input className="input" type="email" placeholder="Email" id="email" autoComplete="username" value={this.state.email} onChange={this.changeEmail} />
          <span className="icon is-small is-left">
            <i className="fas fa-envelope" />
          </span>
        </div>
      </div>
      <div className="field">      
        <label className="label" htmlFor="name">Your name</label>
        <div className="control">
          <input className="input" type="text" placeholder="Name" id="name" value={this.state.name} onChange={this.changeName}  />
        </div>
      </div>      
      <div className="field">      
        <label className="label" htmlFor="help">How can we help?</label>
        <div className="control">
          <textarea className="textarea" type="text" placeholder="How can we help?" id="help" value={this.state.help} onChange={this.changeHelp}   />
        </div>
      </div>
      <input type="text" name="a_password" style={{display:'none'}} tabindex="-1" autocomplete="off" value={this.state.a_password} onChange={this.changePwd}></input>
      {!this.state.status && 
        
      <button type="submit" className="button is-primary" disabled={this.state.inProgress || !this.state.email || !this.state.help || !this.state.name} onClick={this.sendMessage}>
        {this.state.inProgress ? <span>Sending...</span> : <span>Send</span>}
        {this.state.inProgress &&
          <span className="icon is-small">
            <i className="fas fa-spinner fa-spin" />
          </span>
        }
      </button>

      }
      {this.state.status && 
          <table className="errortbl">
          <tbody>
            <tr>
              <td><i className="fas fa-check-circle fa-2x success" /></td>
              <td>Thank you, your message has been sent!</td>
            </tr>
          </tbody>
        </table>  
      }      
    </div>);
  }

  render() {
    return this.renderCapture();
  }
}

export default Contact;