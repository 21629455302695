import './App.scss';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Contact from './Contact';

function App() {
  
  const [isActive, setisActive] = React.useState(false);

  return (
    <>
    <section class="hero is-info is-fullheight">
      <div class="hero-head">
          <nav class="navbar">
              <div class="container">
                  <div class="navbar-brand">
                      <a class="navbar-item" href="../">
                          <img src="/ardent-logo.png" alt="Logo"></img>
                      </a>
                      <a
                        onClick={() => {
                          setisActive(!isActive);
                        }}
                        role="button"
                        className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarMenu"
                      >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                      </a>
                  </div>
                  <div id="navbarMenu" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                      <div class="navbar-end">
                          <span class="navbar-item">
                              <a class="button is-white is-outlined" href="#">
                                  <span>Home</span>
                              </a>
                          </span>
                          <span class="navbar-item">
                              <AnchorLink className="button is-white is-outlined" href="#obid">
                                  <span>OBId&reg;</span>
                              </AnchorLink>
                          </span>
                          <span class="navbar-item">
                              <AnchorLink className="button is-white is-outlined" href="#verify">
                                  <span>GOV.UK Verify</span>
                              </AnchorLink>
                          </span>
                          <span class="navbar-item">
                              <AnchorLink class="button is-white is-outlined" href="#gateway">
                                  <span>UK Government Gateway</span>
                              </AnchorLink>
                          </span>
                          <span class="navbar-item">
                              <AnchorLink class="button is-white is-outlined" href="#contact">
                                  <span>Contact Us</span>
                              </AnchorLink>
                          </span>
                      </div>
                  </div>
              </div>
          </nav>
          </div>

          <div class="hero-body">
              <div class="container has-text-centered">
                  <div class="column is-6 is-offset-3">
                      <h1 class="title">
                          Ardent Technology
                      </h1>
                      <h2 class="subtitle">
                          Ardent Technology is a digital identity solution specialist, developing scalable identity solutions for over 15 years.
                      </h2>
                  </div>
              </div>
          </div>

    </section>
    <section id="obid" class="hero is-info is-fullheight">
      <div class="hero-body">
          <div class="container has-text-centered">
              <div class="column is-6 is-offset-3">
                  <h1 class="title">
                      OBId&reg;
                  </h1>
                  <h2 class="subtitle">
                    <a href="https://www.obid.id">OBId&reg;</a> is an IAM solution designed to give service providers confidence in the identity of their new and existing customers when they interact online.
                  </h2>
                  <div class="content has-text-left">
                    <p>It allows the individual to utilise their existing banking relationship to prove their identity and create an OBId&reg;.</p>
                    <p>It allows the individual to use their OBId&reg; to assert their identity to a new service provider. The service provider receives information from the bank that could be used to start the new account set up.</p>
                    <p>When the customer subsequently engages with the service provider as an existing customer, the individual can use the OBId&reg; credential to authenticate to the service.</p>
                    <p class="has-text-left">What's in it for the service provider?
                      <ul>
                        <li>Adoption of a standards based identity solution</li>
                        <li>Follows the UK Government’s Good Practice Guides GPG44 &amp; GPG45</li>
                        <li>Knowledge that their customer’s identity satisfies the Joint Money Laundering Steering Group guidance associated with the prevention of money laundering and terrorist financing</li>
                        <li>Knowledge that the individual successfully authenticated to the bank using their online banking login thereby proving they are the real person when creating their OBId&reg;</li>
                        <li>Re-assurance that the bank will continue to monitor the individual’s conduct in relation to the prevention of money laundering and terrorist financing guidance</li>
                        <li>Ability to define the frequency at which the individual has to re-use their banking credentials to prove that they still have the bank account thereby satisfying the requirement to monitor the assurance of the identity via OBId&reg;</li>
                        <li>Utilises a highly secure method of authentication using the OBId&reg; credential</li>
                        <li>No requirement to store customers’ passwords</li>
                        <li>Removes the requirement to send a security code via SMS</li>
                        <li>Removes the need to show the ‘NASCAR’ screen of IdPs where an OBId&reg; has been detected</li>
                      </ul>
                    </p>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section id="verify" class="hero is-info is-fullheight">
      <div class="hero-body">
          <div class="container has-text-centered">
              <div class="column is-6 is-offset-3">
                  <h1 class="title">
                      GOV.UK Verify - Experian ID
                  </h1>
                  <h2 class="subtitle">
                    Ardent Technology developed the software that powers <a href="https://www.experian.co.uk/identity-and-fraud/govuk-verify/">Experian ID</a>.
                  </h2>
                  <div class="content has-text-left">
                    <p>Our software and digital identity experience enabled Experian to supply the first Identity Provider certified and operational on GOV.UK Verify.</p>
                    <p class="has-text-left">Our solution included:
                      <ul>
                        <li>A multi tenanted whitelabeled and policy driven Identity Provider and Identity as a Service (IDaaS) solution</li>
                        <li>Multi factor authentication including SMS, Voice and M-Pin aligned with GPG44</li>
                        <li>A score based knowledge based authentication system driven from credit bureau data</li>
                        <li>A score based assurance scheme that verified a multitude of identity evidence, assuring identities to GPG45 at LoA2 and LoA1</li>
                        <li>An Android and iOS app for authentication and document and selfie capture</li>
                        <li>Horizontal scalability to millions of users</li>
                        <li>Fraud prevention utilising device monitoring, velocity checks, PEPs, Sanctions, CIFAS amongst others</li>
                        <li>The solution was tScheme approved, PCI compliant and audited</li>
                      </ul>
                    </p>
                    <p>Experian ID also powers identity assurance behind a UK consumer finance information and online discussion website that allows you access to your credit score and increases your likelihood of getting credit products with a better deal.</p>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section id="gateway" class="hero is-info is-fullheight">
      <div class="hero-body">
          <div class="container has-text-centered">
              <div class="column is-6 is-offset-3">
                  <h1 class="title">
                      UK Government Gateway
                  </h1>
                  <h2 class="subtitle">
                    Ardent Technology developed one of the first SAML 2.0 federated identity implementations for the Government Gateway.
                  </h2>
                  <div class="content has-text-left">
                    <p>The SAML 2.0 implementation was used in the Identity Broker (a Hub that connected to multiple Identity Providers), and also in the Shared Identity Provider that authenticated Gateway users.</p>
                    <p>We also led development of the Government Gateway <a href="https://ntouk.files.wordpress.com/2015/06/annex-j-uk-government-gateway-remote-authentication-by-jim-purves.pdf">Chip &amp; Pin authentication method</a> and pages that were used for Local Authority and MoD employee access to shared services.</p>
                    <p>We were a key member of the Verified Claims team at Microsoft; developing solutions involving NHS smart cards, verified claims, and zero-knowledge proof (U-Prove) technology.</p>
                  </div>
              </div>
          </div>
      </div>
    </section>
    <section id="contact" class="hero is-info is-fullheight">
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="content">
            <Contact />
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default App;
